html{
    font-size: 16px;
    font-family: 'Hind Madurai', sans-serif;
    box-sizing: border-box;
} 

body {
}

* {
	box-sizing: border-box;
}
