.slider__section {
	h2 {
		font-size: 2.5rem;
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: 0.073rem;
		text-align: center;
	}
}

.slider {
	width: 100%;
	height: calc(100vw * 0.375);
	li {
		background-image: url("https://images.unsplash.com/photo-1473445361085-b9a07f55608b?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=c8dccca34a41c87fef98e6ca7957126b&auto=format&fit=crop&w=1384&q=80");
		background-size: cover;
		background-position: center;
		height: 100%;
		width: 100%;
	}
}

@media only screen and (max-width: 1050px) {
	.slider {
		height: 80vh;
	}
}
