form {
	position: relative;
	z-index: 5;
	display: block;
}
.form__label {
	display: block;
	margin: 0 auto 1.25rem auto;
	width: 50%;
	height: 2.813rem;
	box-sizing: border-box;
	color: #4a4a4a;
	font-family: "Hind Madurai";
	font-size: 0.875rem;
	font-weight: 600;
	padding-left: 1.25rem;
	text-transform: uppercase;
	letter-spacing: 0.146rem;
	outline: none;
	background-color: #ffffff;
	&::placeholder {
		color: #4a4a4a;
		font-family: "Hind Madurai";
		font-size: 0.875rem;
		font-weight: 600;
		text-transform: uppercase;
		letter-spacing: 0.146rem;
	}
}

.form__textarea {
	display: block;
	width: 50%;
	margin: 0 auto;
	height: 7rem;
	box-sizing: border-box;
	color: #4a4a4a;
	font-family: "Hind Madurai";
	font-size: 0.875rem;
	font-weight: 600;
	padding-left: 1.25rem;
	padding-top: 0.75rem;
	text-transform: uppercase;
	letter-spacing: 0.146rem;
	outline: none;
	background-color: #ffffff;
	&::placeholder {
		color: #4a4a4a;
		font-family: "Hind Madurai";
		font-size: 0.875rem;
		font-weight: 600;
		text-transform: uppercase;
		letter-spacing: 0.146rem;
	}
}

@media only screen and (max-width: 1500px) {
	.form__label {
	display: block;
	width: 70%;
	margin: 0 auto 2rem auto;
	}
	.form__textarea {
		width: 70%;
		margin: 0 auto;		
	}
}

@media only screen and (max-width: 600px) {
	.form__label {
	display: block;
	width: 80%;
	margin: 0 auto 2rem auto;
	}
	.form__textarea {
		width: 80%;
		margin: 0 auto;		
	}
}