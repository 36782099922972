.arrow-right {
	transition: color 0.15s ease-in;	
}
.contact__button {
	color: #4a4a4a;
	font-family: "Hind Madurai";
	font-size: 1rem;
	font-weight: 700;
	line-height: 2.063rem;
	text-transform: uppercase;
	letter-spacing: 0.089rem;
	text-decoration: none;	
	display: inline-block;
	overflow: hidden;
	margin-top: 2rem;
	transition: color 0.15s ease-in;	
	position: relative;
	&:hover:after {
		width: 100%;
	}
	&:hover .arrow-right {
		fill: #0aa699;
		stroke: #0aa699;
	} 
	&:hover:before {
		left: 100%;
		transition: 0.15s ease-in;
	}
	&:hover {
		color: #0aa699;
	}
	&:before {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 0.125rem;
		content: "";
		background-color: #4a4a4a;
	}
	&:after {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 0%;
		height: .125rem;
		transition: width 0.15s ease-in;
		transition-delay: 0.25s;
		content: "";
		background-color: #0aa699;
		z-index: 5;
	}
}

.card__button {
	display: inline-block;
	border-radius: 0.313rem;
	text-decoration: none;
	color: #4a4a4a;
	font-size: 1rem;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 0.087rem;
	width: 14.375rem;
	height: 3.375rem;
	border: 1px solid #fff;
	text-align: center; 
	overflow: hidden;
	position: relative;
	z-index: 5;
	transition: all 0.2s ease-in;
	&:hover {
		color: rgba(255, 255, 255, 0);
		background-color: transparent;
	}
	&:hover:before {
		transform: translateY(0%);
	}
	&:hover:after {
		transform: translateY(100%);
	}
	&:after {
		position: absolute;
		transition: 0.25s ease-in;
		background-color: #ffffff;
		color: #4a4a4a;
		height: 100%;
		width: 100%;
		transform: translateY(0%);
		left: 0;
		content: "ZAČÍT VYHLEDÁVAT";
		line-height: 3.375rem;
		font-size: 1rem;
		font-weight: 600;
	}
	&:before {
		position: absolute;
		transition: 0.25s ease-in;
		height: 100%;
		width: 100%;
		transform: translateY(-100%);
		left: 0;
		content: "ZAČÍT VYHLEDÁVAT";
		color: #fff;
		line-height: 3.375rem;
		font-size: 1rem;
		font-weight: 600;
	}
}

.submit__button {
	display: block;
	background-color: transparent;
	outline: none;
	border: none;
	height: 2.063rem;
	color: #ffffff;
	overflow: hidden;
	font-family: "Hind Madurai";
	font-size: 1rem;
	font-weight: 700;
	line-height: 2.063rem;
	text-transform: uppercase;
	letter-spacing: 0.089rem;
	cursor: pointer;
	margin: 2.5rem auto 2rem auto;
	position: relative;
	transition: 0.15s ease-in;
	&:hover #a5 {
		stroke: #02FAC0;
		fill: #02FAC0;
	}
	&:hover:after {
		width: 100%;
	}
	&:hover:before {
		left: 100%;
		transition: 0.15s ease-in;
	}
	&:hover {
		color: #02FAC0;
	}
	&:before {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 0.125rem;
		content: "";
		background-color: #fff;
	}
	&:after {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 0%;
		height: .125rem;
		transition: width 0.15s ease-in;
		transition-delay: 0.2s;
		content: "";
		background-color: #02FAC0;
		z-index: 5;
	}
}