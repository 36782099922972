body {
    width: 100vw;
    overflow-x: hidden;
}

.container{
    width: 90%;
    max-width: 1200px;
    margin: auto;

    @media(max-width: $lg){
        width: 920px;
    }

    @media(max-width: $md){
        width: 680px; 
    }

    @media(max-width: $sm){
        width: 94%;
    }
}
.float{
    &--left{
        float: left;
    }
    &--right{
        float: right;
    }
}
.row{
    display: flex;
    flex-direction: row;

    &--vertical-center{
        align-items: center;
    }

    &--vertical-top{
        align-items: flex-start;
    }

    &--vertical-bottom{
        align-items: flex-end;
    }

    &--horizontal-right{
        justify-content: flex-end; 
    }

    &--horizontal-center{
        justify-content: center;
    }

    &--space-between{
        justify-content: space-between;
    }

    &--space-around{
        justify-content: space-around;
    }

    &--stretch{
        align-items: stretch;
    }

    &--full-height{
        height: 100%;
    }

    &--relative{
        position: relative;
    }

    &--wrap{
        flex-wrap: wrap;
    }
}

.col{
    box-sizing: border-box;
    flex-basis: auto;
    flex-shrink: 0;

    &--flex{
        display: flex;
        flex-direction: column;
    }

    &--space-between{
        justify-content: space-between;
    }

    &--space-around{
        justify-content: space-around;
    }

    &--full-height{
        height: 100%;
    }

    &--relative{
        position: relative;
    }

    &--no-shrink{
        flex-shrink: 0;
    }
    
    &--grow {
        flex-grow: 1;
    }

    &--background{
        background-color: #fff;
    }
}


@for $i from 0 through $rows {
    .col--#{$i} { 
        flex-basis: (100% / $rows) * $i;
        max-width: (100% / $rows) * $i;
    }
}

@each $breakpoint, $max-width in $breakpoints {
    @for $i from 0 through $rows {
        .col--#{$breakpoint}-#{$i} { 
            @media(max-width: $max-width){
                flex-basis: (100% / $rows) * $i;
                max-width: (100% / $rows) * $i;
            }
        }
    }
}

@for $i from 0 through 30 {
    .padding-top-#{$i} { 
        padding-top: #{$i}rem;
    }

    .padding-bottom-#{$i} { 
        padding-bottom: #{$i}rem;
    }

    .padding-left-#{$i} { 
        padding-left: #{$i}rem;
    }

    .padding-right-#{$i} { 
        padding-right: #{$i}rem;
    }


    .margin-bottom-#{$i} { 
        margin-bottom: #{$i}rem;
    }

    .margin-top-#{$i} { 
        margin-top: #{$i}rem;
    }

    .margin-right-#{$i} { 
        margin-right: #{$i}rem;
    }
    
    .margin-left-#{$i} { 
        margin-left: #{$i}rem;
    }
}

@each $breakpoint, $max-width in $breakpoints {
    @for $i from 0 through 30 {
        .padding-top-#{$breakpoint}-#{$i} { 
            @media(max-width: $max-width){
                padding-top: #{$i}rem;
            }
        }

        .padding-bottom-#{$breakpoint}-#{$i} { 
            @media(max-width: $max-width){
                padding-bottom: #{$i}rem;
            }
        }

        .padding-left-#{$breakpoint}-#{$i} { 
            @media(max-width: $max-width){
                padding-left: #{$i}rem;
            }
        }

        .padding-right-#{$breakpoint}-#{$i} { 
            @media(max-width: $max-width){
                padding-right: #{$i}rem;
            }
        }


        .margin-bottom-#{$breakpoint}-#{$i} { 
            @media(max-width: $max-width){
                margin-bottom: #{$i}rem;
            }
        }

        .margin-top-#{$breakpoint}-#{$i} { 
            @media(max-width: $max-width){
                margin-top: #{$i}rem;
            }
        }

        .margin-right-#{$breakpoint}-#{$i} { 
            @media(max-width: $max-width){
                margin-right: #{$i}rem;
            }
        }
		
		.margin-left-#{$breakpoint}-#{$i} { 
            @media(max-width: $max-width){
                margin-left: #{$i}rem;
            }
        }
    }
}