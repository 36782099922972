.header__heading {
	color: #4a4a4a;
	font-size: 3.438rem;
	font-weight: 700;
	line-height: 4.438rem;
	text-transform: uppercase;
	letter-spacing: 0.166rem;
	text-align: center;
}

.about__heading {
	color: #4a4a4a;
	font-size: 3.438rem;
	font-weight: 700;
	line-height: 4.438rem;
	text-transform: uppercase;
	letter-spacing: 0.166rem;
	text-align: right;
}

.card__heading {
	color: #ffffff;
	font-size: 3.438rem;
	font-weight: 700;
	margin-bottom: 1rem;
	line-height: 4.438rem;
	text-transform: uppercase;
	letter-spacing: 0.166rem;
	z-index: 5;
}

.card__subheading {
	color: #ffffff;
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 4.438rem;
	text-transform: uppercase;
	letter-spacing: 0.073rem;
	z-index: 5;
}

.contact__heading {
	color: #ffffff;
	font-size: 2rem;
	font-weight: 600;
	letter-spacing: 0.148rem;
	margin-bottom: 1.5rem;
}

.contact__subheading {
	color: #ffffff;
	display: block;
	text-decoration: none;
	font-size: 1.75rem;
	line-height: 3rem;
	font-weight: 600;
	letter-spacing: 0.131rem;
	margin-left: 1rem;
}

.form__heading {
	color: #ffffff;
	font-size: 2rem;
	font-weight: 700;
	line-height: 4.438rem;
	text-transform: uppercase;
	letter-spacing: 0.097rem;
	text-align: center;
}

@media only screen and (max-width: 1500px) {
	.card__heading {
		font-size: 2.75rem;
		line-height: 3.438rem;
	}

	.card__subheading {
		font-size: 1.25rem;
		font-weight: 700;
		line-height: 3.438rem;
	}
}

@media only screen and (max-width: 1366px) {
	.header__heading {
	font-size: 2.75rem;
	line-height: 3.75rem;
}
}

@media only screen and (max-width: 1150px) {
	.header__heading {
	font-size: 2.25rem;
	font-weight: 700;
	line-height: 3.25rem;
}
}

@media only screen and (max-width: 1100px) {
	.about__heading {
		font-size: 3rem;
		font-weight: 700;
		line-height: 3.75rem;
	}
}

@media only screen and (max-width: 1000px) {
	.card__heading {
	font-size: 2.5rem;
	line-height: 3.25rem;
	}
}

@media only screen and (max-width: 860px) {
	.about__heading {
		font-size: 2.5rem;
		font-weight: 700;
		line-height: 3.25rem;
		text-align: left;
	}
	.header__heading {
	font-size: 2rem;
	line-height: 2.75rem;
	}
	.card__subheading {
		font-size: 1.5rem;
		font-weight: 700;
		line-height: 2.75rem;
	}
}

@media only screen and (max-width: 670px) {
	.header__heading {
		font-size: 1.75rem;
		line-height: 2.25rem;
	}
}

@media only screen and (max-width: 540px) {
	.header__heading {
		font-size: 1.5rem;
		line-height: 2rem;
	}
	.contact__heading {
		font-size: 1.15rem		
	}

	.contact__subheading {
		font-size: 1.15rem;
	}
}

@media only screen and (max-width: 420px) {
	.about__heading {
		font-size: 2rem;
		font-weight: 700;
		line-height: 2.5rem;
	}
	.header__heading {
		font-size: 1.25rem;
		line-height: 1.75rem;
	}
}

@media only screen and (max-width: 380px) {
	.header__heading {
		font-size: 1.25rem;
		line-height: 1.5rem;
		letter-spacing: 0.1rem;
	}
	.card__heading {
		font-size: 2.15rem;
		line-height: 2.75rem;
	}
}