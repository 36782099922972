/* US */
@media only screen and (max-width: 380px) {
	.us--horizontal-centered {
		justify-content: center;
	}

	.us--horizontal-left {
		justify-content: flex-start !important;
	}

	.us--horizontal-right {
		justify-content: flex-end;
	}
	.row--us--wrap {
		flex-wrap: wrap;
	}
}

/* XXS */
@media only screen and (max-width: 570px) {
	.xxs--horizontal-centered {
		justify-content: center;
	}

	.xxs--horizontal-left {
		justify-content: flex-start;
	}

	.xxs--horizontal-right {
		justify-content: flex-end;
	}
	.row--xxs--wrap {
		flex-wrap: wrap;
	}
}


/* XS */

@media only screen and (max-width: 860px) {
	.xs--horizontal-centered {
		justify-content: center;
	}

	.xs--horizontal-left {
		justify-content: flex-start;
	}

	.xs--horizontal-right {
		justify-content: flex-end;
	}	
}


/* SM */
@media only screen and (max-width: 1050px) {
.sm--horizontal-centered {
	display: flex;
	flex-direction: row;
	justify-content: center;
	}
	.sm--horizontal-right {
		justify-content: flex-end;
	}
	.sm--horizontal-left {
		justify-content: flex-start;
	}
}
	

/* XXS */
@media only screen and (max-width: 1350px) {
	.md--horizontal-centered {
		justify-content: center;
	}

	.md--horizontal-left {
		justify-content: flex-start;
	}

	.md--horizontal-right {
		justify-content: flex-end;
	}
}

.md-show {
	display: none;
}

.md-hide {

}

@media only screen and (max-width: 1000px) {
	.md-hide {
		display: none;
	}
	.md-show {
		display: inline-block;
	}
}