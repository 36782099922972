.about__paragraph {
	color: #4a4a4a;
	font-size: 1rem;
	font-weight: 600;
	line-height: 2.063rem;
	text-transform: uppercase;
	letter-spacing: 0.089rem;
}

.footer__text {
	color: #4a4a4a;
	font-size: 0.875rem;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 0.146rem;
}

.card__paragraph {
	color: #ffffff;
	font-size: 1rem;
	font-weight: 600;
	line-height: 2.063rem;
	text-transform: uppercase;
	letter-spacing: 0.089rem;
	width: 60%;
	z-index: 5;
}

.customer__paragraph {
	color: #4a4a4a;
	font-size: 1rem;
	position: relative;
	font-weight: 600;
	line-height: 2.063rem;
	text-transform: uppercase;
	letter-spacing: 0.089rem;
	text-align: center;
	margin-top: 1.5rem;
	margin-bottom: 2rem;
	z-index: 5 !important;
}

.customer__name {
	color: #4a4a4a;
	font-size: 1.125rem;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 0.037rem;
	z-index: 1;
	text-align:center;
}

.contact__paragraph {
	color: #ffffff;
	text-decoration: none;
	display: block;
	font-size: 1.125rem;
	line-height: 1.5rem;
	font-weight: 400;
	letter-spacing: 0.157rem;
}

@media only screen and (max-width: 1250px) {
	.customer__paragraph {
		font-size: 0.95rem;
		font-weight: 600;
		line-height: 1.75rem;
	}
}

.footer__link {
	color: #4a4a4a;
	display: block;
	font-family: "Hind Madurai";
	font-size: 0.875rem;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 0.146rem;
	text-decoration: none;
	text-align: right;
}

@media only screen and (max-width: 1500px) {
	.card__paragraph {
		font-size: 0.9rem;
		line-height: 1.75rem;
		width: 80%;
	}
}

@media only screen and (max-width: 540px) {
	.contact__paragraph {
		font-size: 0.9rem;
		line-height: 1.25rem;
	}
}

@media only screen and (max-width: 860px) {
	.footer__link {
		text-align: center;
	}
	.footer__text {
		text-align: center;
		line-height: 1.25rem;
	}
	.footer__span {
 		display: block;
 	}
}

@media only screen and (max-width: 570px) {
	.footer__link {
		text-align: center;
		line-height: 1.25rem;
	}
	.footer__text {
		text-align: center;
		line-height: 1.25rem;
	}
	.footer__span {
 		display: block;
 	}
}