.arrow-down {
	path {
		transition: 0.05s ease-in;
	}
	&:hover path{
		fill: #0aa699;
	}
}

.logo_img {
	z-index: 5;
	position: relative;
	display: block;
	margin: 0 auto;
	width: 70%;
	max-width: 233px;
	height: auto;
}

.ampersand__icon {
	display: block;
	height: 1.875rem;
}

.arrow-right {
	display: inline-block;
	margin-left: 1rem;
}

.building__blue {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 56.6%;
	max-height: 100%;
}

.building__purple {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 26.6%;
	max-height: 100%;
}

.customer-shape-bg {
	position: absolute;
	left: 50%;
	top: 8.6%;
	transform: translate(-50%);
	width: 45%;
	max-height: 11.813rem;
}

.about__section__bgshape {
	position: absolute;
	left: 4.2%;
	bottom: 0;
	min-height: 100%;
}

.contact__image__background {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
}

.form__image__background {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
}

.mobile-navigation__close {
	position: absolute;
	top: 2rem;
	right: 2rem;
	height: 1.2rem;
	cursor: pointer;
	width: 1.2rem;
	display: block;
	z-index: 101;
}

.close-bar {
	display: block;
	right: 0;
	width: 1.5rem;
	height: 3px;
	background-color: #4a4a4a;
	&:first-child {
		transform: rotate(45deg) translate(4px,8px);
	}
	&:last-child {
		transform: rotate(-45deg) translate(-6px,2px);
	}
}

.mobile-navigation__icon {
	position: fixed;
	cursor: pointer;
	right: 2rem;
	display: none;
	top: 2rem;
}



.phone-icon {
	height: 1.75rem;
	overflow: hidden;
	display: block;
	&:hover #a4 {
		fill: #02FAC0;		
	}
	&:hover #b4 {
		fill: #02FAC0;
	}
}

.mail-icon {
	height: 1.75rem;
	overflow: hidden;
	display: block;
	&:hover #a3 {
		fill: #02FAC0;		
	}
	&:hover #b3 {
		fill: #02FAC0;
	}
}

.instagram-icon {
		height: 2.25rem;
		overflow: hidden;
		display: block;
	&:hover #a2 {
		fill: #02FAC0;
	}
	&:hover #b2 {
		fill: #02FAC0;
	}
	&:hover #c2 {
		fill: #02FAC0;
	}
	&:hover #d2 {
		fill: #02FAC0;
	}
}

.linkedin-icon {
	height: 2.25rem;
	width: 2.25rem;
	display: block;
	margin-left: 1rem;
	&:hover path {
		fill: #02FAC0;
	}
}

.fb-icon {
	height: 2.25rem;
	overflow: hidden;
	display: block;
	&:hover #a1 {
		fill: #02FAC0;
	}
	&:hover #b1 {
		fill: #02FAC0;
	}
	&:hover #c1 {
		fill: #02FAC0;
	}
}

.mobile-bar {
	display: block;
	right: 0;
	width: 1.5rem;
	height: 3px;
	background-color: #4a4a4a;
	transition: 0.25s ease-in;
	&:first-child {
		margin-bottom: 0.25rem;
	}
	&:nth-child(2) {
		transition-delay: 0.15s;
	}
	&:last-child {
		margin-top: 0.25rem;
		transition-delay: 0.35s;
	}
}

.slideaway {
		transform: translateX(2rem);
		opacity: 0;
		transition: 0.25s ease-in;
}

@media only screen and (max-width: 1000px) {
	.mobile-navigation__icon {
		display: block;
	}
}

@media only screen and (max-width: 860px) {
	.about__section__bgshape {
		position: absolute;
		left: 4.2%;
		bottom: 0;
		max-height: 100%;
	}
}

@media only screen and (max-width: 625px) {
	.mobile-navigation__icon {
		right: 1rem;
	}
}

@media only screen and (max-width: 570px) {
	.mobile-navigation__close {
		right: 1rem;
	}
}