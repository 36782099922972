
.middle-padding {
	padding: 0 3.33rem;
}

.left-pad {
	padding-left: 1.66rem;
}

.right-pad {
	padding-right: 1.66rem;
}

.footer--main {
	height: 5rem;
}

.welcome-text__wrapper {
	margin-top:  -15.3vh;
	max-width: 100%;
}

.about__section {
	background-color: #F1F1F1;
	overflow: hidden;
	max-height: 515px;
}

.overview__section {
	border: 0.063rem solid #6f828a;
	background-color: #153b51;
	color: #ffffff;
	h2 {
		color: #ffffff;
		font-size: 1.5rem;
		font-weight: 700;
		letter-spacing: 0.073rem;
		text-align: center;
		line-height: 2rem;
	}

	p {
		font-weight: 600;
		line-height: 1.25rem;
		text-transform: uppercase;
		letter-spacing: 0.089rem;
		position: relative;
		&:before {
			position: absolute;
			left: -2rem;
			color: #153b51;
			content: attr(data-order);
			background-color: #fff;
			width: 1.25rem;
			height: 1.25rem;
			line-height: 1.25rem; 
			text-align: center;
		}
	}
}

.client__section {
	background-color: #f6f6f6;
	.ampersand__icon {
		margin: 0 auto;
	}
	p {
		color: #4a4a4a;
		font-size: 1rem;
		font-weight: 600;
		line-height: 2.063rem;
		text-transform: uppercase;
		letter-spacing: 0.089rem;
		text-align: center;
		width: 45%;
		margin: 0 auto;
	}
}

.subsection__alternative {
	background-color: #153b51;
	position: relative;
	&:after {
		background-size: cover;
		background-position: center;
		background-image: url("../img/subsection.png");
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		content: "";
		opacity: 0.4;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		position: absolute;  
	}
	h2, h3, p {
		color: #fff;
		position: relative;
		z-index: 1;
		text-align: center;
		text-transform: uppercase;
	}
	h2 {
		color: #ffffff;
		font-size: 3.438rem;
		font-weight: 700;
		line-height: 4.438rem;
		letter-spacing: 0.166rem;
	}
	h3 {
		color: #ffffff;
		font-size: 1.5rem;
		font-weight: 700;
		line-height: 4.438rem;
		letter-spacing: 0.073rem;
	}

	p {
		color: #ffffff;
		font-size: 1rem;
		font-weight: 600;
		line-height: 1.75rem;
		letter-spacing: 0.089rem;
		width: 94%;
		margin: 0 auto;
	}
	.card__button {
		margin: 2rem auto 0 auto;
		display: block;
	}
}
.subsection {
	background-color: #153b51;
	position: relative;
	&:after {
		background-size: cover;
		background-position: center;
		background-image: url("../img/subsection.png");
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		content: "";
		opacity: 0.4;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		position: absolute;  
	}
	h2, h3, p {
		color: #fff;
		position: relative;
		z-index: 1;
		text-align: center;
		text-transform: uppercase;
	}
	h2 {
		color: #ffffff;
		font-size: 3.438rem;
		font-weight: 700;
		line-height: 4.438rem;
		letter-spacing: 0.166rem;
	}
	h3 {
		color: #ffffff;
		font-size: 1.5rem;
		font-weight: 700;
		line-height: 4.438rem;
		letter-spacing: 0.073rem;
	}

	p {
		color: #ffffff;
		font-size: 1rem;
		font-weight: 600;
		line-height: 1.75rem;
		letter-spacing: 0.089rem;
		width: 80%;
		margin: 0 auto;
	}
	.card__button {
		margin: 2rem auto 0 auto;
		display: block;
	}
}

.search__card {
	position: relative;
	display: flex;
	justify-content: center;
	flex-direction: column;
	box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.23);
	&:after {
		background-size: cover;
		background-position: center;
		content: "";
		opacity: 0.2;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		position: absolute;  
	}
	&--blue {
		background-color: #1f5b73;
		padding-left: 5rem;
		&:after {
			background-image: url("https://images.unsplash.com/photo-1470173274384-c4e8e2f9ea4c?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=8f8bf185da8d21fad65f2636d6021ba7&auto=format&fit=crop&w=1350&q=80");
		}
	}
	&--purple {
		background-color: #153b51;
		padding-right: 5rem;
		text-align: right;
		align-items: flex-end;
		&:after {
			background-image: url("https://images.unsplash.com/photo-1470173274384-c4e8e2f9ea4c?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=8f8bf185da8d21fad65f2636d6021ba7&auto=format&fit=crop&w=1350&q=80"); 
		}
	}
}

.card-padding-left {
	padding-left: 2.5rem;
}

.card-padding-right {
	padding-right: 2.5rem;
}

.customer__card {
	background-color: rgba(216, 216, 216, 0.23);
	position: relative;
	height: 100%;
	padding: 0 6rem;
}

.partners__section {
	min-height: 12.5rem;
	width: 100%;
	background-color: rgba(216, 216, 216, 0.23);
}

.contact__card {
	background-color: #153b51;
	min-height: 660px;
	overflow: hidden;
}



@media only screen and (max-width: 1250px) {
	.search__card {
		&--purple {
			padding-right: 2rem;
		}
		&--blue {
			padding-left: 2rem;
		}
	}
}

@media only screen and (max-width: 1050px) {
		.card-padding-left {
		padding-left: 1rem;
		}

	.card-padding-right {
		padding-right: 1rem;
	}

	.subsection {
		.col > h2 {
			font-size: 2.5rem;
			line-height: 3.5rem;
		}
		h3 {
			font-size: 1.25rem;
			line-height: 2.538rem;
		}

		p {
			width: 95%;
		}
	}

	.subsection__alternative {
		.col > h2 {
			font-size: 2.5rem;
			line-height: 3.5rem;
		}
		h3 {
			font-size: 1.25rem;
			line-height: 2.538rem;
		}

		p {
			width: 95%;
		}
	}

	.overview__section {
		p {
			font-size: 0.9rem;
			line-height: 1.25rem;
			&:before {
				top: 0.25rem;
			}
		}
	}
}

@media only screen and (max-width: 860px) {
	.customer__card {
		padding: 0 2rem;
	}
	.card-padding-right, .card-padding-left {
		padding: 0;
	}
	.about__section {;
		max-height: 780px;
}
}

@media only screen and (max-width: 1600px) {
	.customer__card {
		padding: 0 3rem;
	}

	.subsection__alternative {
		h2 {
			font-size: 3rem;
			line-height: 3.5rem;
		}
		h3 {
			font-size: 1.25rem;
			line-height: 2.538rem;
		}

		p {
			font-size: 0.9rem;
		}
	}


	.subsection {
		h2 {
			font-size: 3rem;
			line-height: 3.5rem;
		}
		h3 {
			font-size: 1.25rem;
			line-height: 2.538rem;
		}

		p {
			font-size: 0.9rem;
		}
	}

	.client__section {
		p {
			width: 65%;
		}
	}
}

@media only screen and (max-width: 1250px) {
	.customer__card {
		padding: 0 1.75rem;
	}
	.client__section {
		p {
			width: 80%;
		}
	}
}

@media only screen and (max-width: 570px) {
	.search__card {
		&--purple {
			padding-right: 1.5rem;
			padding-left: 1.5rem;
		}
		&--blue {
			padding-left: 1.5rem;
			padding-right: 1.5rem;
		}
	}

	.client__section {
		p {
			width: 90%;
		}
	}

	.overview__section {
		h2 {
			font-size: 1.25rem;
			line-height: 1.75rem;
		}
	}
}

@media only screen and (max-width: 600px) {
	.subsection {
		.col > h2 {
			font-size: 2.25rem;
			line-height: 2.75rem;
		}
	}
	.subsection__alternative {
		.col > h2 {
			font-size: 2rem;
			line-height: 2.5rem;
		}
	}
}

@media only screen and (max-width: 1350px) {
	.middle-padding {
	padding: 0 1.83rem;
	}
}

@media only screen and (max-width: 1150px) {
	.middle-padding {
		padding: 0 1.25rem;
	}
	.left-pad {
	padding-left: 0.5rem;
	}

	.right-pad {
		padding-right: 0.5rem;
	}
}

@media only screen and (max-width: 860px) {
	.middle-padding {
		padding: 0;
	}
	.left-pad {
	padding-left: 0;
	}

	.right-pad {
		padding-right: 0;
	}
}

@media only screen and (max-width: 450px) {
	.client__section {
		p {
			width: 100%;
		}
	}

	.overview__section {
		p {
			padding-left: 0.5rem;
		}
	}
}