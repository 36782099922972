 .partner__logo {
 	width: 15%;
 	padding: 1rem;
 }

  @media only screen and (max-width: 1000px) {
 	.partner__logo {
 		width: 30%;
 		padding: 2rem;
 	}
 }

 @media only screen and (max-width: 570px) {
 	.partner__logo {
 		width: 45%;
 		padding: 2rem;
 	}
 }

  @media only screen and (max-width: 380px) {
 	.partner__logo {
 		width: 47%;
 		padding: 1.5rem 0.5rem;
 	}
 }
