.main-navigation {
	background-color: #fff;
	box-sizing: border-box;
	height: 5rem;
	line-height: 5rem;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	padding: 0 5rem;
	box-sizing: border-box;
	position: fixed;
	transition: top 0.25s ease-in;
	z-index: 99;
}

.navigation--offscreen {
	position: fixed;
	top: -5rem;
}

.navigation--onscreen {
	position: fixed;
	top: 0;
}

.nav__logo {
	display: block;
	position: absolute;
	width: 200px;
	top: 50%;
	left: 5rem;
	transform: translateY(-50%);
	&:hover path {
		fill: #0aa699;
	}
}

.nav__contact {
	color: #4a4a4a;
	font-family: "Hind Madurai";
	font-size: 0.875rem;
	font-weight: 600;
	letter-spacing: 0.146rem;
	cursor: pointer;
	transition: all 0.15s ease-in;
	text-decoration: none;
	&:hover {
		color: #0aa699;
	}
}

.nav__list {
	padding: 0;
	display: inline-block;
	li {
		display: inline-block;
	}
}

.nav__item {
	color: #4a4a4a;
	font-family: "Hind Madurai";
	font-size: 0.875rem;
	font-weight: 600;
	padding: 0 1.25rem;
	text-transform: uppercase;
	text-decoration: none;
	letter-spacing: 0.146rem;
	transition: color 0.15s ease-in;
	&:hover {
		color: #0aa699;
	}
	&:last-child {
		padding: 0 0 0 1.25rem;
	}
}

@media only screen and (max-width: 1150px) {
	.nav__item {
		font-size: 0.85rem;
		padding: 0 1rem;
		letter-spacing: 0.1rem;
	}
	.nav__contact {
		font-size: 0.85rem;
	}
}

@media only screen and (max-width: 1100px) {
	.nav__item {
		font-size: 0.8rem;
		padding: 0 1rem;
		letter-spacing: 0.1rem;
	}
	.nav__contact {
		font-size: 0.8rem;
	}
}

@media only screen and (max-width: 1030px) {
	.nav__item {
		font-size: 0.75rem;
		letter-spacing: 0.1rem;
	}
	.nav__contact {
		font-size: 0.75rem;
	}
}

@media only screen and (max-width: 1000px) {
	.nav__list {
		display: none;
	}
}

.nav__list--mobile {
	display: none;
}

@media only screen and (max-width: 1000px) {
	.nav__list--mobile {
		position: fixed;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		height: 100vh;
		width: 100vw;
		box-sizing: border-box;
		padding: 5rem 0;
		z-index: 100;
		left: 0;
		top: -100vh;
		box-sizing: border-box;
		background-color: #fff;
		transition: 0.25s ease-in;
		li {
			display: block;
			text-align: center;
		}
		.nav__item {
			display: block;
			&:last-child {
				padding: 1rem 0;
			}
		}
	}
	.is-showing {
		top: 0;
		transition-delay: 0.45s;
	}
}

@media only screen and (max-width: 1350px) {
	.main-navigation {
		padding: 0 2rem;
	}
	.nav__logo {
		left: 2rem;
	}
}

@media only screen and (max-width: 625px) {
	.main-navigation {
		padding: 0 1rem;
	}
	.nav__logo {
		left: 1rem;
	}
}