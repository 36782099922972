.main-header {
	width: 100%;
	height: 100vh;
	margin: 0 auto;
	box-sizing: border-box;
}

.header__background {
	height: 100vh;
	background-image: url("../img/header_bg.png");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.header__logo {
	display: block;
	width: 70%;
	margin: 0 auto;
}

@media only screen and (max-width: 1366px) {
	.header__logo {
		width: 70%;
	}
}

@media only screen and (max-width: 1150px) {
	.header__logo {
		width: 65%;
	}
}